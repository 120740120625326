@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    margin: 0;
    padding: 0;
    min-height: calc(100vh - calc(100vh - 100%));
    height: 100%;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

body > div {
    height: 100%;
}
